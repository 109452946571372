<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-alert :dismissible="false" class="purple white--text" dark border="left" icon="fab fa-vimeo">
                        <span>Versions</span>
                    </v-alert>
                </v-col>
				<v-col cols="12" class="text-right mb-8">
					<v-row>
						<v-col cols="auto">
                            <!-- 选择项目 -->
                            <v-btn small tile class="purple white--text" @click="project = null">ALL</v-btn>   
                        </v-col>
                        <v-col cols="auto">
                            <v-select class="fs-12" dense v-model="project" label="Project" :items="projects"></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                        	<!-- 添加新的版本 -->
							<v-btn tile small color="purple white--text" @click="showNewVersionDialog">New Version</v-btn>
                        </v-col>
                    </v-row>
				</v-col>
				<v-col cols="12">
					<v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
					<!-- 表头 -->
					<v-data-table dense :headers="versionsHeaders" :items="versions" hide-default-footer v-if="!loading">
						<!-- 表数据 -->
						<template v-slot:body="{ version }">
							<tbody>
								<tr v-for="(version, index) in versions" :key="version.id">
									<td>{{(indexNo - 1) * pageSize +(index+1)}}</td>
									<td>{{ version.project }}</td>
									<td>{{ version.version }}</td>
									<td>{{ common.formatTime(version.timestamp) }}</td>
									<td>
										<v-btn icon class="ml-2" @click="showDeleteDialog(version.id)">
											<v-icon small>fas fa-trash-alt</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
					<v-divider v-if="!loading"></v-divider>
					<!-- 底部分页 -->
					<template>
					<div class="text-xs-center mt-5" v-if="totalElements > 0">
						<v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" circle v-model="pageNo" color="purple" :length="totalPage" @input="getVersions" :total-visible="15"></v-pagination>
					</div>
					</template>
				</v-col>
            </v-row>
        </v-container>
        <!-- 新增 -->
		<v-dialog v-model="versionDialog" persistent max-width="500px">
		    <!-- 模态框 -->
		    <v-card>
		        <v-card-title>
		            <span class="fs-24">{{ versionDialogTitle }}</span>
		            <v-spacer></v-spacer>
		            <v-btn @click="versionDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
		        </v-card-title>
		        <v-card-text>
		            <v-container>
		                <v-form ref="versionForm" v-model="versionFormValid">
		                    <v-row>
		                        <v-col cols="6">
		                        	<v-select dense v-model="versionForm.project" label="Project" :items="projects" required></v-select>
		                        </v-col>
		                        <v-col cols="6">
		                        	<v-text-field dense v-model="versionForm.version" label="Version" required clearable></v-text-field>
		                        </v-col>
		                    </v-row>
		                </v-form>
		            </v-container>
		        </v-card-text>
		        <v-card-actions>
		            <v-spacer></v-spacer>
		            <v-btn v-if="versionDialogTitle == 'New Version'" class="purple--text" text @click="addVersion" :disabled="!versionFormValid" :loading="versionLoading">Save</v-btn>
		        </v-card-actions>
		    </v-card>
		</v-dialog>
		<!-- 删除 -->
		<v-dialog v-model="deleteDialog" persistent max-width="500px">
		    <!-- 模态框 -->
		    <v-card>
		        <v-card-title>
		            <span class="fs-24">Delete Version</span>
		            <v-spacer></v-spacer>
		            <v-btn @click="deleteDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
		        </v-card-title>
		        <v-card-text class="mt-3">
		            <div class="fs-20">Do you want to delete this version?</div>
		        </v-card-text>
		        <v-card-actions>
		            <v-spacer></v-spacer>
					<v-btn text tile color="orange white--text" @click="deleteDialog = false">Cancel</v-btn>
					<v-btn class="red--text" text @click="deleteVersion">Delete</v-btn>
		        </v-card-actions>
		    </v-card>
		</v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                versionsHeaders: [
                	{ text: 'No', value: 'no', sortable: false },
                    { text: 'Project', value: 'project', sortable: false },
					{ text: 'Version', value: 'version', sortable: false },
					{ text: 'Time', value: 'timestamp', sortable: true },
					{ text: 'Action', value: 'action', sortable: false }
                ],
                project: null,
                projects: [],
                versions: [],
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 15,
                menu:false,
				indexNo:0,
				loading:false,
				versionDialog: false,
				versionDialogTitle: '',
				versionLoading: false,
				versionForm: {
					project: null,
					version: null
				},
				deleteVersionId: null,
			    deleteDialog: false
            }
        },
        computed: {
            ...mapGetters(['user']),
            versionFormValid:{
            	get(){
            		return this.versionForm.project != null && this.versionForm.version != null;
            	},
            	set(v){

            	}
            }
        },
        watch: {
            project(newVal,oldVal){
                if(newVal != oldVal){
                    this.pageNo = 1;
                    this.getVersions(1);
                }
            },
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getProjects();
			this.getVersions(1);
        },
        created() {
           
        },
        methods: {
        	// 查询项目
        	getProjects(){
		       	let api = config.baseUrl + '/version/public/projects';
		       	this.$http.get(api).then(response => {
	               if (response.data.code == 200) {
	                   this.projects = response.data.result;
	               }
	           })
        	},
		   	// 获取通知数据
		   	getVersions(pageNo) {
			   this.loading = true;
			   let api = null;
			   if (this.project != null) {
					api = config.baseUrl + '/version/private/versions/' + this.project + '?pageNo=' + (pageNo - 1) + '&pageSize=' + this.pageSize;
			   } else {
			   		api = config.baseUrl + '/version/private/versions?pageNo=' + (pageNo - 1) + '&pageSize=' + this.pageSize;
			   }
		       this.$http.get(api).then(response => {
	               if (response.data.code == 200) {
					   this.loading = false;
	                   this.versions = response.data.result.versions;
	                   this.totalElements = response.data.result.totalElements;
	                   this.totalPage = Math.ceil(this.totalElements / this.pageSize);
	                   this.indexNo = pageNo;
	               }
	           })
		   	},
		   // 添加系统通知
		   addVersion(){
			   if (this.$refs.versionForm.validate()) {
			       let api = config.baseUrl + '/version/private/add';
				   this.versionLoading = true;
			       this.$http.post(api, this.versionForm).then(response => {
			           let data = response.data;
			           if (data.code == 200) {
			               this.$store.dispatch('snackbarMessageHandler', "Success");
						   this.versionDialog = false;
			               this.getVersions(1);
						   this.$refs.versionForm.reset();
						   this.versionLoading = false;
			           } else {
			           		this.$store.dispatch('snackbarMessageHandler', data.message);
			           		this.$refs.versionForm.reset();
			           		this.versionLoading = false;
			           }
			       }, error => {
			           this.$store.dispatch('snackbarMessageHandler', "Error");
			           this.versionLoading = false;
			       });
			   }
		   },
		   // 显示新增通知弹窗
		   showNewVersionDialog(){
				this.versionDialogTitle = 'New Version';
				this.versionForm = {
					project: null,
					version: null
				};
				this.versionDialog = true;
		   },
		   // 显示删除通知弹窗
		   showDeleteDialog(id){
			   this.deleteVersionId = id;
			   this.deleteDialog = true;
		   },
		   // 删除版本
		   deleteVersion(){
		   	let api = config.baseUrl + '/version/private/delete?id=' + this.deleteVersionId;
			this.deleteDialog = false;
		   	this.$http.delete(api).then(response => {
		   	    let data = response.data;
		   	    if (data.code == 200) {
		   	        this.$store.dispatch('snackbarMessageHandler', data.message);
		   	        this.pageNo = 1;
                    this.getVersions(1);
		   	    } else {
		   	    	this.$store.dispatch('snackbarMessageHandler', data.message);
		   	    }
		   	}, error => {
		   	    this.$store.dispatch('snackbarMessageHandler', data.message);
		   	});
		   }
        }
    }
</script>

<style>

</style>